/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');




html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  /* font-family: Roboto, "Helvetica Neue", sans-serif; */
  background-color: #0C0705 !important;

}

.mat-expansion-panel-header-description, .mat-expansion-indicator::after {
  color: white !important;
}
.pacifico-font {
  font-family: "Pacifico", cursive !important;
}
.roboto-font{
  font-family: Roboto !important;
}
.stardosstencil-font  {

  font-family: "stardos stencil", cursive !important;
}
.righteous-font{
  font-family: 'Righteous', cursive;
}
.pointer {
  cursor: pointer;
}

.overhide {
  overflow: hidden;
}

.scrollx {
  overflow-x: scroll;
  -ms-overflow-style: none;
}

.scrolly {
  overflow-y: scroll;
  -ms-overflow-style: none;
}

.scrollx::-webkit-scrollbar {
  display: none;
}

.scrolly::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 768px) {
  .scrollx {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .scrollx::-webkit-scrollbar {
    display: none;
  }
}

.remove-highlight {
  outline: none;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-small {
  width: 120px !important;
}

.btn-medium {
  width: 150px !important;
}

.btn-large {
  width: 250px !important;
}

.btn-white {
  background-color: #ffffff !important;
  width: 150px;
}

.btn-primary-col {
  background-color: #141126 !important;
}

.btn-secondary-col {
  background-color: #43db6b !important;
}

.btn-accent-col {
  background-color: #ce608d !important;
}
.btn-cust-blue{
  background-color: #9ec9ff !important;
}
.btn-cust-blue:hover{

  background-color: #6b93c5 !important;
}

.btn-primary-outline {
  border-color: #141126 !important;
  color: #141126 !important;
}

.btn-secondary-outline {
  border-color: #43db6b !important;
  color: #43db6b !important;
}

.text-primary-col {
  color: #141126 !important;
}

.text-secondary-col {
  color: #43db6b !important;
}

.text-gradient-col {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to bottom, #30d86a, #b9004c);
}

.bg-primary-col {
  background-color: #0C0705 !important;
}

.bg-secondary-col {
  background-color: #43db6b !important;
}

.bg-translucent {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.border-1 {
  border: 1px solid;
}

.border-bottom-4 {
  border-bottom: 4px solid;
}

.border-primary-col {
  border-color: #141126 !important;
}

.border-secondary-col {
  border-color: #43db6b !important;
}

.border-rounded-10 {
  border-radius: 10px !important;
}

.border-rounded {
  border-radius: 30px !important;
}

.rounded-top-left {
  border-radius: 30px 0px 0px 0px !important;
}

.rounded-top-right {
  border-radius: 0px 30px 0px 0px !important;
}

.rounded-bottom-right {
  border-radius: 0px 0px 30px 0px !important;
}

.rounded-bottom-left {
  border-radius: 0px 0px 0px 30px !important;
}

.border-rounded-left {
  border-radius: 30px 0px 0px 30px !important;
}

.border-rounded-right {
  border-radius: 0px 30px 30px 0px !important;
}

.auth {
  background-image: url(assets/auth.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.auth-header {
  position: relative;
  background-color: rgba(255, 255, 255, 0.3);
  text-align: center;
  padding: 35px;
}

.auth-header::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  height: 100px;
  width: 150px;
  background-image: url(assets/music-icon-left.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.auth-header::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  height: 100px;
  width: 100px;
  background-image: url(assets/music-icon-right.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.auth-content {
  background-color: rgba(255, 255, 255, 0.3);
  padding-top: 35px !important;
  padding-bottom: 150px !important;
}

.form-auth {
  width: 40%;
}

.auth-form {
  background-color: rgba(255, 255, 255, 0.6) !important;
  color: #000000 !important;
  /* border-color: rgba(255, 255, 255, 0.6) !important; */
}

.auth-form::placeholder {
  color: #000000 !important;
}

.dash-bg {
  /* min-height: 400px; */


}

.dash-bg img {
  height: auto;
  background-size: contain;
  max-width: 100%;
  object-fit: cover;
}

.hover-section {
  background-color: rgba(0, 0, 0, 0.6);
}

.line-listing ul li {
  display: inline-block !important;
  margin-right: 15px !important;
}

ul {
  list-style: none;
  padding: 0;
}

@media only screen and (max-width: 1199px) {
  .landing-heading {
    font-size: 70px !important;
  }

  .auth-content {
    padding-top: 35px !important;
    padding-bottom: 150px !important;
  }

  .form-auth {
    width: 50%;
  }
}

@media only screen and (max-width: 991px) {
  .landing-heading {
    font-size: 60px !important;
  }

  .auth-content {
    padding-top: 35px !important;
    padding-bottom: 100px !important;
  }

  .form-auth {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .landing-heading {
    font-size: 40px !important;
  }

  .auth-content {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }

  .form-auth {
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .auth-content {
    padding-top: 35px !important;
    padding-bottom: 50px !important;
  }
}

.mat-dialog-container {
  overflow: unset !important;
  padding: 0 !important;
  border-radius: 10px !important;
  background-color: black !important;
  color: white !important;
}



.dialogBack {
  background-color: rgba(255, 255, 255, 0.4);
}

a,
a:active,
a:focus,
a:active {
  text-decoration: none !important;
}

input,
select,
textarea {
  /* color: #ffffff !important; */
}

option {
  background-color: #141126 !important;
  color:white !important;
}

/* app_css */

a,
a:active,
a:focus,
a:active {
  color: #222222;
  text-decoration: none !important;
}
input {
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
a:hover,
a:focus,
input:focus,
input:hover,
select:focus,
select:hover,
select:active,
textarea:focus,
textarea:hover,
button:focus {
  outline: none;
}
/* input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]),
textarea {
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #999;
  display: inline-block;
  padding: 5px 10px;
} */

dl {
  margin-bottom: 1.6em;
}
dt {
  font-weight: bold;
}
dd {
  margin-bottom: 1.6em;
}

iframe {
  width: 100%;
}


h1,
.h1 {
  line-height: 148px;
  font-size: 88px;
  font-weight: 400;
  margin-bottom: 33px;
  font-family: "Pacifico", cursive;
}

h2,
.h2 {
  font-size: 60px;
}

h3,
.h3 {
  font-size: 38px;
  font-weight: 500;
}

h4,
.h4 {
  font-size: 27px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* p {
  font-size: 18px;
  line-height: 33px;
  margin-bottom: 25px;
} */
p:last-child {
  margin: 0;
}

input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #000000;
  opacity: 1;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #000000;
  opacity: 1;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #000000;
  opacity: 1;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000;
  opacity: 1;
}
input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000;
  opacity: 1;
}

input::placeholder {
  /* Most modern browsers support this now. */
  color: #000000;
  opacity: 1;
}

/*---------------------------------------------------
HOME PAGE STYLESHEET
----------------------------------------------------*/
.main-body {
  position: relative;
}
header.header-section {
  background-color: #141126;
  padding: 20px 100px;
}
.header-right-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header-user {
  display: flex;
  align-items: center;
  position: relative;
}
.user-data {
  margin-right: 40px;
}
.user-data h4 {
  color: #1cb953;
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 5px;
}
.user-data p {
  font-size: 13px;
  color: #b4b4b4;
  line-height: 1;
}
.header-search {
  position: relative;
  margin-right: 60px;
}
.header-search input {
  width: 270px;
  height: 43px;
  color: #fff !important;
  border-radius: 30px;
  border: 1px solid #ffffff !important;
  background-color: transparent;
}
.search-icon {
  position: absolute;
  top: 5px;
  right: 25px;
  cursor: pointer;
}
.header-search input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #fff;
  opacity: 1;
}
.header-search input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
  opacity: 1;
}
.header-search input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
  opacity: 1;
}
.header-search input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
  opacity: 1;
}
.header-search input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
  opacity: 1;
}

.header-search input::placeholder {
  /* Most modern browsers support this now. */
  color: #fff;
  opacity: 1;
}
.user-thumb {
  position: relative;
}
.user-thumb:before {
  content: "";
  left: 50%;
  top: 50%;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  border-radius: 100%;
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transform: translate(-50%, -50%);
}
.angle {
  position: absolute;
  top: 12px;
  right: -25px;
  color: #1cb953;
}

.fixed-menu-wrap {
  width: 329px;
  height: 100%;
  background-color: #141126;
  position: fixed;
  padding: 20px 15px 20px 20px;
  z-index: 1;
}
.main-logo {
  padding: 44px 20px 70px 20px;
  text-align: center;
}
.fixed-menu ul li {
  margin-bottom: 25px;
}
.fixed-menu ul li:last-child {
  margin-bottom: 0;
}
.fixed-menu ul li a img {
  margin-right: 15px;
}
.fixed-menu ul li a {
  color: #fff;
  font-size: 19px;
  font-weight: 300;
}
.fixed-menu ul li a {
  color: #fff;
  font-size: 19px;
  font-weight: 300;
  display: block;
  border-radius: 39px;
  padding: 10px 25px;
  transition: all 0.4s;
  border: 1px solid transparent;
}
.fixed-menu ul li a.active {
  color: #1cb953;
  border: 1px solid #fff;
}
.fixed-menu ul li a:hover {
  color: #1cb953;
  border: 1px solid #fff;
}
.fixed-menu ul li a:hover .menu-icon {
  fill: #1cb953;
}
.fixed-menu ul li a svg {
  margin-right: 20px;
}

.fixed-menu ul li a .menu-icon {
  fill: #fff;
  transition: all 0.4s;
}

.fixed-menu ul li a.active .menu-icon {
  fill: #1cb953;
}

.main-section {
  padding-left: 380px;
}
.dashed-board-banner {
  min-height: 400px;
  background-size: cover;
  background-position: center;
}
.music-area {
  margin-top: -39px;
}
.music-wrap-column {
  display: flex;
  flex-wrap: wrap;
  padding: 0 30px;
  max-width: 1200px;
  /* margin: 0 30px; */
}
.music-wrap {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px;
  z-index: 0;
  border-radius: 8px;
  margin: 0 15px;
  flex: 0 0 30%;
}
.music-wrap:before {
  content: "";
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(13px);
  backdrop-filter: blur(13px);
  position: absolute;
  /*    background-color: rgba(255, 255, 255, 0.4);
*/
  overflow: hidden;
  transition: all 0.4s;
  box-shadow: inset 0 0 0 500px rgba(255, 255, 255, 0.4);
}
.music-wrap:hover:before {
  background-color: #1cb953;
}
.music-data {
  text-align: right;
}
.music-data h3 {
  font-size: 38px;
  margin: 0;
}
.music-data p {
  font-size: 17px;
  margin: 0;
  color: #fff;
}

.single-thumb-section {
  text-align: center;
  padding: 210px 0;
}
.single-data h3 {
  font-weight: 300;
  margin-top: 40px;
}

/*index 2*/
.star-section {
  padding: 60px 0 85px;
}

.star-group {
  max-width: 710px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.star-title {
  margin-bottom: 30px;
}
.star-title h4 {
  font-size: 29px;
  color: #30d86a;
  font-weight: normal;
  margin-bottom: 10px;
}
.star-title p {
  color: #e2e2e2;
}
.star-group-item p {
  font-size: 23px;
  font-weight: 300;
}
.star-group-item {
  text-align: center;
}
.star-group-item h2 {
  font-weight: 800;
  font-size: 70px;
  margin: 0;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(to bottom, #30d86a, #b9004c);
  -webkit-text-fill-color: transparent;
}
.overview-section {
  padding-bottom: 50px;
}
.overview-progress-wrap {
  background-color: #232031;
  padding: 65px 65px 50px 40px;
}
.overview-progress-wrap img {
  width: 100%;
  height: 100%;
}
.overview-month {
  border-top: 1.5px solid #cfcfcf;
  position: relative;
  left: 51px;
  margin: 0 29px;
  top: -13px;
}
.overview-month ul {
  display: flex;
  justify-content: space-around;
  padding-top: 50px;
}
.overview-month ul li {
  font-size: 21px;
  display: inline-block;
}

.overview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}
.overview-header-left h3 {
  font-size: 29px;
  color: #30d86a;
  margin: 0;
  font-weight: normal;
}
.overview-header-left ul li {
  display: inline-block;
  font-size: 17px;
  font-weight: 300;
  margin-right: 50px;
}

.select-dropdown select {
  width: 220px;
  border: none;
  height: 51px;
  color: #fff;
  font-weight: 300;
  padding: 10px 34px;
  border-radius: 4px;
  cursor: pointer;
  -moz-appearance: none;
  appearance: none;
  background: url(assets/arrow.svg) no-repeat scroll 86%, #232031;
}
.select-dropdown.date-dropdown select {
  /* background: #232031; */
}

.default-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 45px 0 50px;
}
.default-header-content h3 {
  color: #30d86a;
  font-size: 29px;
  font-weight: normal;
}

.default-header-content p {
  font-size: 17px;
  color: #e2e2e2;
}

a.add-now-btn {
  width: 220px;
  height: 51px;
  display: inline-block;
  border-radius: 26px;
  background-color: #29cc61;
  text-align: center;
  padding: 10px;
  font-size: 17px;
  color: #141126;
  font-weight: 500;
  transition: all 0.4s;
}
a.add-now-btn:hover {
  background-color: #fff;
}
.gallery-bar {
  margin-bottom: 60px;
}
.gallery-bar ul li {
  margin: 0 10px;
  display: inline-block;
}
.gallery-bar ul li:first-child {
  margin-left: 0;
}
.gallery-bar ul li:last-child {
  margin-right: 0;
}
.gallery-bar ul li a {
  font-size: 25px;
  color: #fff;
}
.gallery-bar ul li a.active {
  color: #30d86a;
}

.date {
  width: 220px;
  border: none;
  height: 51px;
  color: #fff;
  font-weight: 300;
  padding: 10px 34px;
  border-radius: 4px;
  background-color: #232031;
  text-align: center;
}
.apps {
  width: 57px;
  height: 51px;
  line-height: 51px;
  background-color: #232031;
  text-align: center;
  border-radius: 4px;
}
.gallery-item {
  margin-bottom: 55px;
}
.gallery-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery-thumb {
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
  z-index: 0;
}
.gallery-data {
  position: relative;
}
.gallery-data p {
  font-size: 18px;
  font-weight: 300;
  margin: 0 0 10px;
  color: #e2e2e2;
  line-height: 1;
}
.gallery-data span {
  font-size: 16px;
  color: #919191;
  display: block;
  line-height: 2;
  margin: 0;
}
span.gallery-date {
  position: absolute;
  right: 0;
  bottom: 0;
}

.video-popup-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transition: all 0.4s;
  transform: translate(-50%, -50%);
}

.edit-delete-btn {
  position: absolute;
  bottom: 15px;
  right: 15px;
  transition: all 0.4s;
  opacity: 0;
}
.edit-delete-btn ul li {
  margin: 0 5px;
  display: inline-block;
}

.icon {
  width: 42px;
  height: 33px;
  border: 1px solid #29cc61;
  border-radius: 26px;
  text-align: center;
  line-height: 31px;
}
.icon img {
  width: 13px;
  height: 13px;
}
.icon.delete {
  border: 1px solid #cc2955;
}
.gallery-item:hover .video-popup-wrap,
.gallery-item:hover .edit-delete-btn,
.gallery-item:hover .gallery-thumb.hover:before {
  opacity: 1;
}

.gallery-thumb.hover:before {
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0;
  position: absolute;
  transition: all 0.4s;
  background-color: rgba(20, 17, 38, 0.79);
}



img.download {
  margin-right: 20px;
}

.package-column {
  max-width: 485px;
  flex: 0 0 33%;
  margin-right: 55px;
  padding: 35px 15px;
  border-radius: 10px 66px 10px 10px;
  background-color: #232031;
}
.package-title {
  margin-bottom: 20px;
}
.package-title p {
  font-size: 24px;
  color: #30d86a;
  padding: 22px 13px;
  border-bottom: 2px solid #090909;
}
.package-title.package-title2 p {
  border-bottom: 2px solid #dcdcdc;
}
.package-body-wrap {
  padding: 0 10px;
}
.package {
  margin-bottom: 30px;
}
.package p {
  color: #fff;
  margin-bottom: 10px;
}
.package span {
  font-size: 14px;
  font-weight: 300;
  color: #c4c4c4;
  line-height: 24px;
  display: block;
}
.package-body ul li img {
  margin-right: 15px;
}
.package-body ul li {
  font-size: 14px;
  font-weight: 300;
  color: #e2e2e2;
}

a.package-btn {
  color: #141126;
  background-color: #32db6c;
  width: 210px;
  display: inline-block;
  text-align: center;
  height: 47px;
  border-radius: 4px;
  padding: 7px;
  transition: all 0.4s;
}
a.package-btn:hover {
  background-color: #fff;
  color: #ce608d;
}
a.package-btn.package-btn2 {
  color: #141126;
  background-color: #fff;
}
.package-footer {
  text-align: center;
  margin-top: 85px;
}
.package-section {
  padding: 0 0 120px;
}
.form-section {
  padding: 50px 0 150px;
}

.form-section .default-header {
  padding: 0 0 50px;
}
.form-title {
  margin-bottom: 30px;
}
.form-title p {
  font-size: 27px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 20px;
}
.form-title p span {
  color: #959595;
  font-size: 17px;
  font-style: italic;
  margin-left: 20px;
}
.form-title h4 {
  font-size: 18px;
  color: #30d86a;
}
.form-wrap {
  margin-right: 80px;
}
.field-column {
  display: flex;
  justify-content: space-between;
}
.input-field {
  flex: 0 0 49%;
  margin-bottom: 20px;
}
.input-field input {
  height: 63px;
  color: #fff !important;
  padding: 10px 30px !important;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #a5a5a5 !important;
}

.input-field input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #b4b4b4;
  opacity: 1;
}
.input-field input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #b4b4b4;
  opacity: 1;
}
.input-field input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #b4b4b4;
  opacity: 1;
}
.input-field input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b4b4b4;
  opacity: 1;
}
.input-field input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b4b4b4;
  opacity: 1;
}

.input-field input::placeholder {
  /* Most modern browsers support this now. */
  color: #b4b4b4;
  opacity: 1;
}
.play-btn {
  text-align: right;
  margin-top: 40px;
}
button#playnow {
  width: 211px;
  height: 47px;
  background-color: #32db6c;
  text-align: center;
  border: none;
  color: #141126;
  font-size: 15px;
  border-radius: 4px;
}

.modal-dialog {
  height: 100%;
  display: flex;
  align-items: center;
}

.file-upload-input {
  display: none;
}

.box-fileupload {
  margin-bottom: 25px;
}
.box-fileupload__lable {
  font-size: 20px;
  margin: 10px 0;
  color: #1471ad;
  background: #d3e7ff;
  height: 250px;
  background-color: #232031;
  width: 100%;
  color: #1db954;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  cursor: pointer;
}

input#fileId {
  display: none;
}

.modal-content.song-details {
  background-color: #141126;
  padding: 45px 45px 70px;
  border-radius: 12px;
}

label.file-upload-btn {
  display: block;
}
.image-field {
  margin-bottom: 28px;
}
.image-field input,
.image-field textarea {
  border: 1px solid #99989b !important;
  height: 57px;
  color: #99989b !important;
  border-radius: 7px;
  background-color: transparent;
}
.image-field textarea {
  height: 100px;
}

.image-field input::-webkit-input-placeholder,
.image-field textarea::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #99989b;
  opacity: 1;
}
.image-field input:-moz-placeholder,
.image-field textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #99989b;
  opacity: 1;
}
.image-field input::-moz-placeholder,
.image-field textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #99989b;
  opacity: 1;
}
.image-field input:-ms-input-placeholder,
.image-field textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #99989b;
  opacity: 1;
}
.image-field input::-ms-input-placeholder,
.image-field textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #99989b;
  opacity: 1;
}

.image-field input::placeholder,
.image-field textarea::placeholder {
  /* Most modern browsers support this now. */
  color: #99989b;
  opacity: 1;
}

button#save-btn {
  border: none;
  font-size: 21px;
  height: 61px;
  font-weight: 500;
  width: 100%;
  border-radius: 31px;
  background-color: #29cc61;
}
.modal-dialog.song-details {
  max-width: 700px;
}

button.btn-close.song-details {
  background-image: url(assets/cross.svg);
  background-color: #1db954;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: 20px;
  opacity: 1;
  position: absolute;
  text-align: center;
  line-height: 45px;
  right: -15px;
  top: -22px;
}
.modal.overlay {
  background-color: rgba(203, 203, 203, 0.46);
}

.modal-dialog.delete-box {
  max-width: 588px;
}
.modal-content {
  text-align: center;
  background-color: #141126;
  border-radius: 27px;
  padding: 50px;
}
.delete-icon {
  margin-bottom: 30px;
}
.delete-content p {
  font-size: 20px;
  color: #fff;
}
.delete-button-wrap {
  margin-top: 50px;
}
a.delete-btn {
  color: #fff;
  display: inline-block;
  width: 176px;
  height: 56px;
  font-size: 19px;
  border: 2px solid #ffffff;
  border-radius: 28px;
  font-weight: 500;
  padding: 10px;
  margin: 0 20px;
  transition: all 0.4s;
}
a.delete-btn:hover {
  color: #fff;
  background-color: #1db954;
  border: 2px solid #1db954;
}

.modal-dialog.subscription-plan {
  max-width: 980px;
}
.modal-content.subscription-plan {
  text-align: left;
}
.subscription-plan-wrap {
  display: flex;
  justify-content: space-between;
}
.subscription-plan-header h3 {
  font-size: 25px;
  font-weight: 500;
  color: #e2e2e2;
  margin-bottom: 25px;
}
.subscription-plan-header p {
  font-size: 14px;
  font-weight: 300;
  color: #c4c4c4;
}

.month-price h2 {
  color: #30d86a;
  font-size: 29px;
  font-weight: normal;
}
.modal-content.subscription-plan {
  text-align: left;
  padding: 70px 80px 60px;
}
.package-body.popup ul {
  column-count: 3;
  margin: 30px 0 70px;
}
.package-body-wrap.popup {
  padding: 0 45px;
}
.package-column.popup {
  margin: 0;
  text-align: center;
  border-radius: 0;
}
.package-title.popup p {
  font-size: 21px;
  padding: 0 0 20px;
  border-bottom: 1px solid #333333;
}
.package.popup span {
  color: #e2e2e2;
  line-height: 29px;
}
.package-footer.popup {
  margin-top: 40px;
}
a.package-btn.popup {
  height: 51px;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
}
a.package-btn:hover {
  color: #141126;
}

.delete-icon.subscriptions-popup {
  width: 132px;
  height: 132px;
  background-color: #29cd61;
  line-height: 132px;
  border-radius: 100%;
  margin: 0 auto;
  margin-top: -110px;
  margin-bottom: 50px;
}
.delete-content h4 {
  font-size: 28px;
  font-weight: normal;
  color: #28cb60;
  margin-bottom: 40px;
}

/*notification page*/

.table-wrap.notification-table th,
td {
  text-align: left;
  padding: 35px 39px 10px 60px;
}

a.table-btn.notification-btn {
  color: #232031;
  background-color: #29cc61;
  text-align: center;
  border-radius: 3px;
  margin-right: 10px;
}

.table-wrap.notification-table td {
  padding: 10px 10px 10px 60px;
  text-align: left;
}
th.title-width {
  width: 750px;
}
th.sr-width {
  width: 170px;
}

/*terms condition*/
.default-header.bottom-less {
  padding: 40px 0 30px;
}
.terms-conditions-wrap {
  background-color: #232031;
  padding: 50px;
  margin-bottom: 100px;
}
.terms-conditions-wrap span {
  font-size: 18px;
  position: relative;
  display: block;
  padding: 0 0 25px;
  margin: 0 0 25px;
}
.terms-conditions-wrap span:before {
  content: "";
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  position: absolute;
  background-color: #000;
}
.terms-conditions-wrap h3 {
  font-size: 35px;
  color: #30d86a;
  margin-bottom: 30px;
}

/*faq*/
.faq-section {
  background-color: #232031;
  padding: 50px;
  margin-bottom: 100px;
}

.accordion-button:not(.collapsed) {
  color: #d6d6d6;
  background-color: transparent;
  text-align: left;
  line-height: 1.5;
  padding: 26px 0;
}
button.accordion-button.collapsed {
  color: #d6d6d6;
  background-color: transparent;
  border-bottom: 1px solid #423f53;
  padding: 26px 0;
  text-align: left;
  line-height: 1.5;
}
.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.accordion-button::after {
  content: "\f067";
  font-size: 33px;
  font-family: "Font Awesome 5 Pro";
  background-image: none;
  color: #30d86a;
  -webkit-text-stroke: 2px #232031;
  margin-right: 50px;
}
.accordion-button:not(.collapsed)::after {
  background-image: none;
  transform: none;
  content: "\f068";
  font-size: 33px;
  font-family: "Font Awesome 5 Pro";
  margin-right: 30px;
}

/*change password*/
.change-password-section {
  background-color: #232031;
  padding: 50px 125px 110px;
}
.profile-thumb {
  position: relative;
  width: 170px;
  height: 170px;
  border-radius: 100%;
  margin-left: 130px;
}
.profile-thumb:before {
  content: "";
  left: 50%;
  top: 50%;
  width: 115%;
  height: 115%;
  border-radius: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  border: 1px solid #575757;
}
.edit-icon {
  width: 48px;
  height: 48px;
  background-color: #29cc61;
  text-align: center;
  line-height: 43px;
  border-radius: 100%;
  color: #fff;
  border: 3px solid #fff;
  cursor: pointer;
  position: absolute;
  bottom: -22px;
  right: 20px;
}
.change-password-wrap {
  width: 100%;
}
.change-password-wrap p {
  color: #30d86a;
  margin-bottom: 20px;
}
.change-password-filed {
  margin-bottom: 20px;
  position: relative;
}
.change-password-filed input {
  height: 63px;
  color: #fff !important;
  border-radius: 5px;
  border: 1px solid #a5a5a5 !important;
  background-color: transparent;
}
.password-show {
  position: absolute;
  top: 15px;
  right: 40px;
  color: #848484;
  font-size: 20px;
}
.change-password-filed input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #b4b4b4;
  opacity: 1;
}
.change-password-filed input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #b4b4b4;
  opacity: 1;
}
.change-password-filed input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #b4b4b4;
  opacity: 1;
}
.change-password-filed input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b4b4b4;
  opacity: 1;
}
.change-password-filed input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b4b4b4;
  opacity: 1;
}

.change-password-filed input::placeholder {
  /* Most modern browsers support this now. */
  color: #b4b4b4;
  opacity: 1;
}
.change-password-btn {
  text-align: right;
  margin-top: 50px;
}
a.change-password {
  background-color: #29cc61;
  display: inline-block;
  width: 220px;
  height: 51px;
  text-align: center;
  border-radius: 30px;
  font-weight: 500;
  padding: 10px;
  margin-right: 20px;
  color: #141126;
}
a.change-password:last-child {
  margin-right: 0;
}

a.change-password.back {
  background-color: #5a5a5a;
}
.thumb {
  margin-left: 70px;
}

.add-input-field {
  text-align: right;
  padding: 10px 0 100px;
}
a.add-more {
  margin-right: 20px;
  color: #30d86a;
  font-size: 15px;
  display: inline-block;
}
a.pluse-icon {
  background-color: #30d86a;
  height: 43px;
  width: 53px;
  display: inline-block;
  text-align: center;
  line-height: 46px;
  border-radius: 5px;
  color: #232031;
  font-size: 22px;
}

p.hide {
  opacity: 0;
}

.iti__flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url(assets/flags.png);
  background-repeat: no-repeat;
  background-color: #dbdbdb;
  background-position: 20px 0;
}

.iti.iti--allow-dropdown {
  width: 100%;
}

input#phone {
  padding: 10px 83px !important;
}
.iti__arrow {
  margin-left: 20px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 9px solid #29cc61;
}

.iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 6px 0 8px;
  left: 8px;
}
.iti__selected-flag:focus {
  border: none;
  outline: none;
}

.iti__arrow--up {
  border-bottom: 0;
}

/* offcanvas menu */
.main-menu {
  display: block;
}
.offcanves-menu {
  display: none;
}
.offcanves-menu {
  width: 300px;
  background: #141126;
  height: 100%;
  position: fixed;
  z-index: 111;
  padding: 62px 20px;
  margin-left: -300px;
  transition: 0.3s;
  padding-right: 0px;
}
.offcanves-menu.active {
  margin-left: 0px;
  z-index: 2;
}

.off-menu span.menubar {
  font-size: 28px;
  cursor: pointer;
  transition: all linear 0.3s;
  color: #ffffff;
}

.off-menu {
  float: right;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #1cb953;
  font-size: 20px;

  transition: all linear 0.3s;
  cursor: pointer;
}
.close:hover {
  color: #009a60;
  border-color: #009a60;
}

.menu-bar {
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
  color: #fff;
  font-size: 35px;
  transform: translateY(-50%);
  cursor: pointer;
  display: none;
}

.offcanvas-overlay.active {
  opacity: 0.3;
  z-index: 111;
  visibility: visible;
}

.menu-bar {
  display: none;
  position: absolute;
  top: 50%;
  right: 25px;
  color: #fff;
  font-size: 35px;
  transform: translateY(-50%);
  cursor: pointer;
  display: none;
}

/* offcanvas menu */
.fixed-menu-wrap {
  display: block;
}

.header-wrap {
  position: relative;
}

a,
a:active,
a:focus,
a:active {
  color: #222222;
  text-decoration: none !important;
}
input {
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
a:hover,
a:focus,
input:focus,
input:hover,
select:focus,
select:hover,
select:active,
textarea:focus,
textarea:hover,
button:focus {
  outline: none;
}


dl {
  margin-bottom: 1.6em;
}
dt {
  font-weight: bold;
}
dd {
  margin-bottom: 1.6em;
}

iframe {
  width: 100%;
}


h1,
.h1 {
  line-height: 148px;
  font-size: 88px;
  font-weight: 400;
  margin-bottom: 33px;
  font-family: "Pacifico", cursive;
}

h2,
.h2 {
  font-size: 60px;
}

h3,
.h3 {
  font-size: 38px;
  font-weight: 500;
}

h4,
.h4 {
  font-size: 27px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}


p:last-child {
  margin: 0;
}


textarea {
  height: 250px;
  resize: none;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #000000;
  opacity: 1;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #000000;
  opacity: 1;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #000000;
  opacity: 1;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000;
  opacity: 1;
}
input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000;
  opacity: 1;
}

input::placeholder,
textarea::placeholder {
  /* Most modern browsers support this now. */
  color: #000000;
  opacity: 1;
}

/*---------------------------------------------------
HOME PAGE STYLESHEET
----------------------------------------------------*/

header.header-section {
  position: absolute;
  z-index: 1;
  width: 100%;
  padding: 45px 0;
}
.header-wrap {
  position: relative;
}
.main-menu {
  text-align: right;
}
.main-menu ul li {
  margin: 0 5px;
  position: relative;
  display: inline-block;
}
.main-menu ul li:first-child {
  margin-left: 0;
}
.main-menu ul li:last-child {
  margin-right: 0;
}
.main-menu ul li a {
  color: #ffffff;
  font-size: 17px;
  text-transform: uppercase;
  display: block;
  min-width: 148px;
  /*min-height: 59px;
*/
  text-align: center;
  border-radius: 30px;
  padding: 14px 13px 12px;
  transition: all 0.4s;
}
.main-menu ul li a:hover {
  color: #1cb953;
}
.main-menu ul li a.active {
  font-weight: 500;
  background-color: #fff;
  color: #0e1f29 !important;
}
.main-menu ul li a.hover:hover {
  background-color: #fff;
  color: #0e1f29 !important;
}

.main-menu ul li a.active.home {
  background-color: #1cb953 !important;
  color: #fff !important;
  border-radius: 0;
}
.main-menu ul li a.hover.home {
  color: #1cb953;
  border-radius: 0;
  position: inherit;
  left: 0;
}
.main-menu ul li a:hover.hover.home {
  background-color: #1cb953 !important;
  color: #fff !important;
  border-radius: 0;
}
.main-menu ul li a i {
  color: #1cb953;
  font-size: 25px;
  position: relative;
  top: 3px;
  margin-left: 10px;
}
.main-menu ul li:hover ul.dropdown {
  bottom: -60px;
  opacity: 1;
  visibility: visible;
}
.main-menu ul li a.hover {
  position: relative;
  left: -20px;
}
ul.dropdown {
  bottom: -75px;
  opacity: 0;
  visibility: hidden;
  min-width: 205px;
  position: absolute;
  transition: all 0.4s;
  background-color: rgba(90, 90, 90, 0.5);
}
ul.dropdown li {
  display: block;
}
ul.dropdown li a {
  color: #3dd672;
  font-size: 15px;
  font-weight: 300;
  padding: 19px 25px;
  display: block;
  min-height: 0;
  text-align: left;
  text-transform: capitalize;
  line-height: 1;
}

/*---------------------------------------------------
FOOTER SECTION
----------------------------------------------------*/

footer.footer-section {
  background-color: #0e1120;
}
.footer-top {
  max-width: 1335px;
  margin: 0 auto;
  padding: 124px 0 0;
}
.footer-widget {
  margin-right: 100px;
}
.footer-title {
  margin-bottom: 30px;
}
.footer-title h4 {
  color: #d6d6d6;
  position: relative;
  padding: 0 0 20px;
}
.footer-title h4:before {
  content: "";
  width: 330px;
  height: 4px;
  left: 0;
  bottom: 0;
  position: absolute;
  background-color: #1cb953;
}
.social {
  margin-top: 35px;
}
.social ul li {
  margin: 0 13px;
  display: inline-block;
}
.social ul li:first-child {
  margin-left: 0;
}
.social ul li:last-child {
  margin-right: 0;
}
.social ul li a {
  font-size: 25px;
  color: #1cb953;
}
.footer-menu ul li {
  margin: 10px 0;
}
.footer-menu ul li:first-child {
  margin-top: 0;
}
.footer-menu ul li:last-child {
  margin-bottom: 0;
}
.footer-menu ul li a {
  color: #d6d6d6;
  font-weight: 500;
  transition: all 0.4s;
}
.footer-menu ul li a:hover {
  color: #1cb953;
}
.footer-info ul li {
  margin-bottom: 20px;
  position: relative;
  padding-left: 45px;
}
.footer-info ul li:last-child {
  margin-bottom: 0;
}
.footer-info ul li a {
  color: #d6d6d6;
}
.footer-info ul li i {
  left: 0;
  position: absolute;
  color: #1cb953;
  font-size: 24px;
  top: 7px;
}
.footer-bottom {
  padding: 60px 0 80px;
}
.footer-bottom p {
  margin: 0;
  color: #ffffff;
  font-size: 17px;
  text-align: center;
}

/*---------------------------------------------------
HERO SECTION
----------------------------------------------------*/

.hero-section {
  min-height: 1280px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  background-size: cover;
}
.hero-content {
  max-width: 765px;
  margin: 0 auto;
}

/*---------------------------------------------------
ABOUT SECTION
----------------------------------------------------*/
.about-section {
  z-index: 0;
  padding: 140px 0;
  position: relative;
  background-size: cover;
  background-position: center;
}

.about-section:before {
  content: "";
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+1,7db9e8+100&0.62+0,0.26+100 */
  background: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0.62) 0%,
    rgba(0, 0, 0, 0.62) 1%,
    rgba(125, 185, 232, 0.26) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.62) 0%,
    rgba(0, 0, 0, 0.62) 1%,
    rgba(125, 185, 232, 0.26) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.62) 0%,
    rgba(0, 0, 0, 0.62) 1%,
    rgba(125, 185, 232, 0.26) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9e000000', endColorstr='#427db9e8',GradientType=1 ); /* IE6-9 */
}
.about-thumb-wrap {
  display: flex;
  flex-wrap: wrap;
}
.about-thumb-item {
  flex: 0 0 50%;
}
.about-content {
  margin-right: 100px;
}
.about-content h2 {
  margin-bottom: 30px;
}
.about-info {
  margin-bottom: 40px;
}
.about-info:last-child {
  margin-bottom: 0;
}
.about-info p {
  font-size: 24px;
  color: #3fd974;
  margin-bottom: 15px;
}
.about-info span {
  font-size: 18px;
  color: #dedede;
  font-weight: 300;
}

/*---------------------------------------------------
COUNT SECTION
----------------------------------------------------*/
.count-section {
  padding: 115px 0 70px;
}
.count-wrap {
  max-width: 830px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.count-number {
  width: 173px;
  height: 173px;
  background-color: #0e1120;
  text-align: center;
  border-radius: 100%;
  line-height: 169px;
  position: relative;
  padding: 10px;
  display: flex;
  z-index: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 65px;
}
.count-number:before {
  content: "";
  left: 50%;
  top: 50%;
  width: calc(100% + 45px);
  height: calc(100% + 45px);
  z-index: -1;
  border-radius: 100%;
  position: absolute;
  border: 2px solid #1cb953;
  transform: translate(-50%, -50%);
}
.count-number h3 {
  margin: 0;
  font-family: "Fraunces", serif;
  font-size: 56px;
}
.count-item h4 {
  color: #051219;
  text-align: center;
  font-size: 42px;
  font-weight: normal;
  font-family: "Fraunces", serif;
}

/*---------------------------------------------------
ABOUT PAGE STYLESHEET
----------------------------------------------------*/
.inner-section {
  min-height: 850px;
  background-size: cover;
}
.section-title {
  text-align: center;
  max-width: 755px;
  margin: 0 auto 90px;
}
.section-title h2 {
  color: #211523;
  font-size: 88px;
  font-weight: 400;
  margin-bottom: 55px;
  font-family: "Pacifico", cursive;
}
.section-title p {
  color: #211523;
  font-weight: 300;
  line-height: 39px;
}
.about2-section {
  padding: 90px 0 100px;
}
.about2-thumb {
  z-index: 0;
  padding-left: 40px;
  padding-right: 35px;
  position: relative;
  margin-right: 55px;
}
.about2-thumb img {
  width: 100%;
  height: 100%;
}

.about2-thumb:before {
  content: "";
  width: 172px;
  height: 100%;
  left: 0;
  top: -35px;
  z-index: -1;
  position: absolute;
  background-color: #211523;
}
.about2-thumb:after {
  content: "";
  width: calc(100% - 220px);
  height: 100%;
  right: 0;
  bottom: -35px;
  z-index: -1;
  position: absolute;
  background-color: #e4e4e4;
}
.about2-content p {
  color: #051219;
  line-height: 41px;
}

/*---------------------------------------------------
INNER PAGE STYLESHEET
----------------------------------------------------*/
.main-step-section {
  padding: 220px 0 165px;
  position: relative;
  z-index: 0;
  background-size: cover;
  background-position: center;
}
.step-wrap {
  max-width: 1250px;
  margin: 0 auto;
}
.step-header {
  margin-bottom: 100px;
  text-align: center;
  padding: 35px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.34);
}
.step-header:before {
  content: "";
  left: 35px;
  top: 35px;
  width: 159px;
  height: 99px;
  position: absolute;
  background-size: 120px;
  background-image: url(assets/music-icon-left.svg);
  background-repeat: no-repeat;
}
.step-header:after {
  content: "";
  right: 0;
  top: 29px;
  width: 139px;
  height: 99px;
  position: absolute;
  background-size: 76px;
  background-image: url(assets/music-icon-right.svg);
  background-repeat: no-repeat;
}
.step-header h3 {
  margin-bottom: 20px;
}
.step-header p {
  font-size: 20px;
  color: #180c1c;
  font-weight: 300;
}

.form-wrap {
  padding: 120px 170px 150px;
  background-color: rgba(255, 255, 255, 0.17);
}

.input-check {
  margin-top: 35px;
}
.step-form-wrap {
  max-width: 536px;
  margin: 0 auto;
}
.step-input-field {
  margin-bottom: 25px;
  position: relative;
}
.eye-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #acacac;
  right: 30px;
}
.input-check input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.input-check label {
  position: relative;
  cursor: pointer;
  color: #fff;
  font-size: 15px;
  font-weight: 300;
}

.input-check label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #fff;
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 30px;
  width: 25px;
  height: 25px;
  border-radius: 2px;
}
.input-check input:checked + label:before {
  background-color: #fff;
}

.input-check input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  left: 9px;
  width: 7px;
  height: 13px;
  border: solid #224652;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.button-area {
  display: flex;
  margin-top: 60px;
  align-items: center;
  justify-content: space-between;
}
a.input-btn {
  background-color: #ce608d;
  width: 251px;
  height: 59px;
  border-radius: 30px;
  display: inline-block;
  font-size: 17px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  padding: 14px;
  transition: all 0.4s;
}
a.input-btn:hover {
  color: #ce608d;
  background-color: #fff;
}

.btn-right {
  text-align: right;
}
.btn-right.text_right {
  width: 100%;
}
.step-form-wrap.step-form-wrap2 {
  max-width: 100%;
}
.step-input-field label {
  font-size: 14px;
  font-style: italic;
  display: block;
  text-align: right;
}
.step-input-field select {
  font-size: 15px;
  margin: 0;
  width: 100%;
  height: 60px;
  font-weight: normal;
  border-radius: 2px;
  color: #141e23 !important;
  font-size: 14px;
  transition: all 0.4s;
  padding: 10px 40px !important;
  border: none !important;
  cursor: pointer;
  -moz-appearance: none;
  appearance: none;
  background: url(assets/arrow.svg) no-repeat scroll 94%
    rgba(242, 242, 242, 0.7);
}
.step-input-field p {
  font-size: 16px;
  margin: 0 0 12px;
  color: #fff;
}
.add-btn {
  text-align: right;
  margin-bottom: 10px;
}
a.add {
  font-size: 16px;
  color: #fff;
  width: 103px;
  height: 36px;
  background-color: #ce608d;
  display: inline-block;
  text-align: center;
  border-radius: 30px;
  padding: 2px;
  text-transform: uppercase;
  transition: all 0.4s;
}
a.add:hover {
  color: #ce608d;
  background-color: #fff;
}

a.cross-btn {
  color: #fff;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #ce608d;
  display: block;
  text-align: center;
  line-height: 37px;
  position: absolute;
  top: -13px;
  right: -8px;
  transition: all 0.4s;
}
a.cross-btn:hover {
  color: #ce608d;
  background-color: #fff;
}

.input-column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.step-input-field {
  flex: 0 0 48%;
}

a.skip {
  font-size: 14px;
  color: #fff;
  margin-right: 50px;
  display: inline-block;
  text-transform: uppercase;
}
a.back-btn {
  color: #ffffff;
  font-size: 14px;
  width: 117px;
  display: block;
}
.back-btn i {
  width: 42px;
  height: 42px;
  color: #002f3f;
  border-radius: 100%;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.2);
  text-align: center;
  line-height: 42px;
  margin-right: 18px;
}

.button-area.step2 {
  margin-top: 200px;
}

.package-wrap {
  display: flex;
  flex-wrap: wrap;
}
.package-column {
  max-width: 485px;
  flex: 0 0 33%;
  margin-right: 55px;
  padding: 35px 15px;
  border-radius: 10px 66px 10px 10px;
  background-color: rgba(255, 255, 255, 0.7);
}
.package-title {
  margin-bottom: 20px;
}
.package-title p {
  font-size: 24px;
  color: #000000;
  padding: 22px 13px;
  border-bottom: 2px solid #ffffff;
}
.package-title.package-title2 p {
  border-bottom: 2px solid #dcdcdc;
}
.package-body-wrap {
  padding: 0 19px;
}
.package {
  margin-bottom: 30px;
}
.package p {
  color: #000000;
  margin-bottom: 10px;
}
.package span {
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  line-height: 24px;
  display: block;
}
.package-body ul li img {
  margin-right: 15px;
}
.package-body ul li {
  font-size: 14px;
  font-weight: 300;
  color: #000;
}

a.package-btn {
  color: #fff;
  background-color: #ce608d;
  width: 210px;
  display: inline-block;
  text-align: center;
  height: 47px;
  border-radius: 4px;
  padding: 7px;
  transition: all 0.4s;
}
a.package-btn:hover {
  background-color: #fff;
  color: #ce608d;
}
a.package-btn.package-btn2 {
  color: #bbbbbb;
  background-color: #fff;
}
.package-footer {
  text-align: center;
  margin-top: 85px;
}
.step-form-wrap h3 {
  color: #fff;
  margin-bottom: 35px;
}
.confirm-button {
  text-align: right;
  margin-top: 50px;
}
button#confirm-btn {
  border: none;
  min-width: 251px;
  height: 59px;
  font-size: 17px;
  color: #fff;
  padding: 10px;
  border-radius: 30px;
  background-color: #ce608d;
  text-transform: uppercase;
  transition: all 0.4s;
}
button#confirm-btn:hover {
  color: #ce608d;
  background-color: #fff;
}

/*trasaction page*/

.trasaction-wrap {
  max-width: 707px;
  background-color: #fff;
  text-align: center;
  margin: 270px auto 200px;
  padding: 85px 50px 50px;
}
.check {
  width: 110px;
  height: 110px;
  display: flex;
  font-size: 50px;
  color: #fff;
  margin: 0 auto 60px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #05bb36;
  box-shadow: 0 0 0 20px rgba(172, 172, 172, 0.21);
}
.check-content h3 {
  color: #05bb36;
  margin-bottom: 20px;
}
.check-content p {
  color: #180c1c;
  font-size: 22px;
  margin: 0;
}
.check-content p span {
  font-weight: 600;
  margin-left: 10px;
}

/*login page*/
.forget-password p {
  font-size: 14px;
  color: #ffffff;
}
.btn-left p {
  font-size: 14px;
  color: #fff;
}
.btn-left p a {
  color: #05bb36;
}
a.input-btn.login {
  width: 155px;
}
.forget-password-title {
  text-align: center;
  max-width: 400px;
  margin: 0 auto 40px;
}
.forget-password-title h3 {
  font-size: 34px;
  margin-bottom: 70px;
}
.forget-password-title p {
  font-size: 16px;
  color: #fff;
}
a.input-btn.getcode {
  width: 213px;
}

/*code page*/
.code {
  text-align: center;
  max-width: 255px;
  margin: 0 auto;
}
.code ul li {
  margin: 0 2px;
  display: inline-block;
}
.code ul li a {
  width: 54px;
  height: 54px;
  display: block;
  font-size: 30px;
  color: #000000;
  text-align: center;
  line-height: 54px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.71);
}
.code p {
  color: #fff;
  margin: 10px 0 0;
  font-size: 17px;
  text-align: right;
}
a.input-btn.submit {
  width: 360px;
  color: #fff;
  background-color: #ce608d;
}

/* offcanvas menu */
.main-menu {
  display: block;
}
.offcanves-menu {
  display: none;
}
.offcanves-menu {
  width: 300px;
  background: #ffffff;
  height: 100%;
  position: fixed;
  z-index: 111;
  padding: 62px 20px;
  margin-left: -300px;
  transition: 0.3s;
  padding-right: 0px;
}
.offcanves-menu ul {
  padding-top: 20px;
}
.offcanves-menu ul li {
  position: relative;
  border-bottom: 1px solid #ddd;
  margin-right: 25px;
}
.offcanves-menu ul li:last-child {
  border-bottom: 0px;
}
.offcanves-menu ul li a {
  padding: 16px 0px;
  display: block;
  color: #333333;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.4s;
}
.offcanves-menu ul li a:hover {
  color: #1cb953;
}
.offcanves-menu.active {
  margin-left: 0px;
  z-index: 1111;
}

.off-menu span.menubar {
  font-size: 28px;
  cursor: pointer;
  transition: all linear 0.3s;
  color: #ffffff;
}

.off-menu {
  float: right;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #333333;
  font-size: 20px;
  padding: 10px 15px;
  border: 1px solid #333333;
  transition: all linear 0.3s;
  cursor: pointer;
}
.close:hover {
  color: #009a60;
  border-color: #009a60;
}

.offcanvas-overlay {
  width: 100%;
  height: 100%;
  background: #000;
  position: fixed;
  z-index: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}

.offcanvas-overlay.active {
  opacity: 0.3;
  z-index: 111;
  visibility: visible;
}

.menu-bar {
  display: none;
  position: absolute;
  top: 50%;
  right: 25px;
  color: #fff;
  font-size: 35px;
  transform: translateY(-50%);
  cursor: pointer;
  display: none;
}

ul.off-dropdown li a {
  padding: 0 0 10px;
}
ul.off-dropdown li a {
  padding: 0 0 10px;
}
ul.off-dropdown {
  padding: 0 0 0 30px;
  transition: all 0.4s;
  opacity: 0;
  height: 0;
}

.offcanves-menu ul li:hover ul.off-dropdown {
  opacity: 1;
  height: 43px;
}
.offcanves-menu ul li a i {
  font-size: 25px;
  margin-left: 10px;
  position: relative;
  top: 4px;
}

